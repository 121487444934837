function Team() {
  return (
    <>
      <div style={{ marginTop: '15rem', width: '100%', height: '10px' }} className="about-scroll"></div>

      <div className="container team">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2 className="main-title team-h2">TEAM</h2>
            <h3 className="sub-title team-h3" style={{ color: '#156082', fontSize: '1.5rem' }}>Partner</h3>
            <p className="main-p" style={{ fontFamily: 'Georgia, serif', textAlign: 'justify' }}>
              <span style={{ color: '#156082' }}>Ismaïl</span> began his career as a quantitative researcher at a major bank. He then spent several years as a strategy and data science consultant within a leading digital and AI strategy consulting firm where he quickly became a Principal and helped build the consultancy's data, AI and cybersecurity practices.
              <br />
              <br />
              <span style={{ color: '#156082' }}>Ismaïl</span> also has experience teaching business economics and finance at HEC Paris’ MiM Grande Ecole Program and has a published scientific paper on AI for Cybersecurity.
              <br />
              <br />
              <span style={{ color: '#156082' }}>Education:</span> HEC Paris, Ecole Polytechnique
            </p>
          </div>
          <div className="col-md-6 text-center">
            <img alt="team" src={process.env.PUBLIC_URL + "/IA.jpg"} className="img-fluid" style={{ maxWidth: '100%', height: 'auto', marginTop: '1rem' }} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Team;

//az webapp up --name cellardoor --resource-group rg-corporate-website
