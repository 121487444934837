import {animateScroll as scroll } from 'react-scroll';

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-6">
                <h1 className="logo">CellarDoor</h1>
                <p className="footer-text">
                  Premier service company specializing in digital strategy,
                  data science, and AI.
                </p>
              </div>
              <div className="col-md-6">
                <p className="footer-title">Publications</p>
                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://arxiv.org/search/cs?searchtype=author&query=Atlas,+I+A+H">Scientific papers</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="side2">
            <div className="row">
              <div className="col-md-6">
                <p className="footer-title">Contact</p>
                <ul>
                  <li>
                    <a href="mailto:ismail.alaoui@cellardoor-group.com" >ismail.alaoui@cellardoor-group.com</a>
                  </li>
                  <li>
                  <span className="footer-text">19, Rue Monsieur, 75007 Paris</span>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <p className="footer-title">Social Media</p>
                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://fr.linkedin.com/in/isma%C3%AFl-alaoui-hassani-atlas-10321879">LinkedIn</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} className="gotop"><i className="fas fa-level-up-alt"></i></button>
    </footer>
  );
}

export default Footer;
