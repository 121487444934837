function Header() {
  return (
    <header style={{ fontFamily: "Georgia, serif" }}>
      <video src={process.env.PUBLIC_URL + '/video.mp4'} loop autoPlay muted playsInline></video>
      <h1>CellarDoor</h1>
      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
