import React from 'react';
import Card from './Card';

function Services() {
  return (
    <div className="container services">
      <h2 className="main-title text-center">SERVICES</h2>
      <div className="row">
        <div className="col-md-6 mb-4">
          <Card
            title="AI Transformation"
            img={process.env.PUBLIC_URL + "/img/AI_Transformation.jpg"}
            text={
              <>
                CellarDoor specializes in AI Transformation, offering strategic <span style={{ color: "#156082" }}>data and AI strategy definition</span>. We provide scalable <span style={{ color: "#156082" }}>AI solutions</span>, set up <span style={{ color: "#156082" }}>data teams</span>, and ensure robust <span style={{ color: "#156082" }}>data governance programs</span>. Our expertise empowers businesses to leverage AI for competitive advantage and innovation.
              </>
            }
          />
        </div>
        <div className="col-md-6 mb-4">
          <Card
            title="AI for Cybersecurity"
            img={process.env.PUBLIC_URL + "/img/AI_Cyber.jpg"}
            text={
              <>
                CellarDoor enhances cybersecurity with AI, focusing on <span style={{ color: "#156082" }}>IT infrastructure protection</span>, <span style={{ color: "#156082" }}>cyber incident tracking</span>, and <span style={{ color: "#156082" }}>threat modeling</span>. We ensure comprehensive <span style={{ color: "#156082" }}>asset and data protection</span> through advanced analytics and <span style={{ color: "#156082" }}>Cyberwatch initiatives</span>.
              </>
            }
          />
        </div>
        <div className="col-md-6 mb-4">
          <Card
            title="Digital Strategy"
            img={process.env.PUBLIC_URL + "/img/Digital_Strategy.jpg"}
            text={
              <>
                CellarDoor designs transformative <span style={{ color: "#156082" }}>Digital Strategy Roadmaps</span>, ideating new <span style={{ color: "#156082" }}>business models</span> and organizing dedicated <span style={{ color: "#156082" }}>digital, data, and AI teams</span>. We lead <span style={{ color: "#156082" }}>digital transformation planning</span>, kickstart initiatives, and drive sustainable growth through innovative digital solutions.
              </>
            }
          />
        </div>
        <div className="col-md-6 mb-4">
          <Card
            title="IT Transformation"
            img={process.env.PUBLIC_URL + "/img/IT_transformation.jpg"}
            text={
              <>
                CellarDoor offers comprehensive <span style={{ color: "#156082" }}>IT Transformation services</span>, crafting <span style={{ color: "#156082" }}>global IT transformation roadmaps</span> and meticulously <span style={{ color: "#156082" }}>steering IT plans</span>. We specialize in <span style={{ color: "#156082" }}>industrializing digital projects</span>, optimizing <span style={{ color: "#156082" }}>cloud strategies</span>, and defining <span style={{ color: "#156082" }}>IT processes and best practices</span> to drive efficiency and scalability.
              </>
            }
          />
        </div>
      </div>
      <style>{`
        @media (max-width: 576px) {
          .services .row {
            display: flex;
            flex-direction: column;
          }
          .services .col-md-6 {
            width: 100%; /* Ensure each card takes full width on small screens */
            margin-bottom: 20px; /* Add space between stacked cards */
          }
        }
      `}</style>
    </div>
  );
}

export default Services;
