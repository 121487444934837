import './Card.css'; // Import the CSS file

function Card(props) {
  const cardStyle = {
    backgroundImage: `url(${props.img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    color: 'black',
    height: '300px',
    borderRadius: '8px',
    overflow: 'hidden',
    marginBottom: '20px',
  };

  const overlayStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    boxSizing: 'border-box',
    textAlign: 'center',
  };

  return (
    <div style={cardStyle}>
      <div style={overlayStyle}>
        <h3 className="card-title">{props.title}</h3>
        <p className="card-text">{props.text}</p>
      </div>
    </div>
  );
}

export default Card;
