function About() {
  return (
    <>
      <div style={{marginTop:'15rem',width:'100%',height:'10px'}} className="about-scroll"></div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center" style={{marginTop: '-20px', paddingTop: '2rem'}}>
          <img alt="about" src={process.env.PUBLIC_URL + "/about.jpg"} className="img-fluid" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2">ABOUT</h2>
            <p className="main-p" style={{ fontFamily: 'Georgia, serif', textAlign: 'justify' }}>
            CellarDoor is a premier service company specializing in digital strategy,
            data science, and AI support. We provide tailored solutions to empower strategic
            decision-making and facilitate the development and deployment of cutting-edge digital
            and AI technologies. Whether you need punctual assistance or comprehensive project
            implementation, CellarDoor is your trusted partner for innovative, data-driven success.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
